#lightHeader {
    font-size: 5rem;
}

#darkHeader {
    font-size: 5rem;
    color: white;
}

#container {
    display: flex;
    align-items: center;
    margin: 100px 0px 10px;
    justify-content: flex-start;
}

@media only screen and (max-width: 450px) {
    #lightHeader {
        font-size: 3.1rem;
    }

    #darkHeader {
        font-size: 3.1rem;
        color: white;
    }

    #container {
        display: flex;
        flex-direction: column;
    }
}