#nameHeader {
    font-size: 8rem;
    margin-top: 40px;
    letter-spacing: 0.225rem;
}

#titleHeader {
    font-size: 2.5rem;
    font-weight: 100;
    margin-bottom: 100px;
}

@media only screen and (max-width: 602px) {
    #nameHeader {
        font-size: 5rem;
        margin-top: 50px;
        letter-spacing: normal;
    }

    #titleHeader {
        font-size: 2rem;
    }
}