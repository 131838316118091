#projectContainer {
    margin: 2rem 0 10rem 0;
}

#descriptionRow, #buttonsRow {
    padding: 0;
}

#projectHeader {
    font-size: 2.5rem;
    padding: 0 3rem;
}

#projectHeaderDark {
    font-size: 2.5rem;
    color: white;
}

#githubButton, #projectButton {
    margin: 0 0.5rem;
}

#projectDescription {
    font-size: 1.25rem;
    padding: 0 3rem;
    line-height: 2rem;
}

#projectDescriptionDark {
    font-size: 1.25rem;
    padding: 0 3rem;
    line-height: 2.5rem;
    color: white;
}

#projectSkills {
    padding: 0 3rem;
    font-weight: 900;
    color: rgba(0,0,0,.4);
}

#projectSkillsDark {
    padding: 0 3rem;
    font-weight: 900;
    color: rgba(255,255,255,.4);
}